import { render, staticRenderFns } from "./InvoiceComponent.vue?vue&type=template&id=35bec3f2&scoped=true&"
import script from "./InvoiceComponent.vue?vue&type=script&lang=js&"
export * from "./InvoiceComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35bec3f2",
  null
  
)

export default component.exports