<template>
  <tbody>
  <tr v-for="i in nRow" :key="i" class="animate-pulse">
    <td v-for="col in nCol" :key="`col_${col}`">
      <p class="h-4 bg-gray-200 w-full"></p>
    </td>
  </tr>
  </tbody>
</template>

<script>
export default {
  name: "TableShimmer",
  props: {
    nRow: {
      type: Number,
      default: 1
    },
    nCol: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style scoped>

</style>