<template>
  <div class="border rounded pb-[10px]">
    <div class="absolute bg-red-30 inset-0" style="z-index:10" @click="openFilter=false"
         v-if="openFilter"></div>
    <div class="block lg:flex justify-between px-[10px]">
      <div class="w-full lg:w-[50%]">
        <h5 class="px-[5px] py-[10px]">{{ $t('git.transactionsLog') }}</h5>
      </div>
      <div class="w-full mb-[30px] lg:w-[50%] flex justify-start lg:justify-end">
        <div class="w-full relative">
          <div class="flex justify-start lg:justify-end">
            <div class="flex w-full lg:w-[60%] mt-[8px] border h-[30px] rounded cursor-pointer"
                 v-on:click="onOpenFilter">
                <span class="w-[20%] px-[6px] py-[4px]">
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.00001 16C7.71668 16 7.47901 15.904 7.28701 15.712C7.09501 15.52 6.99934 15.2827 7.00001 15V9L1.20001 1.6C0.950009 1.26667 0.912343 0.916667 1.08701 0.55C1.26168 0.183334 1.56601 0 2.00001 0H16C16.4333 0 16.7377 0.183334 16.913 0.55C17.0883 0.916667 17.0507 1.26667 16.8 1.6L11 9V15C11 15.2833 10.904 15.521 10.712 15.713C10.52 15.905 10.2827 16.0007 10 16H8.00001ZM9.00001 8.3L13.95 2H4.05001L9.00001 8.3Z"
                    fill="#8F8E94"/>
                </svg>
              </span>
              <span class="w-[80%] px-[10px] py-[4px]">{{ $t('filter') }}</span>
            </div>
          </div>
          <div class="w-full absolute z-20 p-[10px] rounded bg-white border shadow w-full lg:w-[73%] right-1"
               v-if="openFilter === true">
            <div class="w-full py-[10px]">
              <label>{{ $t('git.transaction.status') }}</label>
              <select class="h-[30px] w-full flex focus:outline-none rounded c-p-2 bg-white custom-border"
                      v-model="paramsQuery.eng_status">
                <option value="">{{ $t('git.transaction.select_status') }}</option>
                <option value="successful">{{ $t('git.transaction.success') }}</option>
                <option value="Error">{{ $t('git.transaction.fail') }}</option>
              </select>
            </div>
            <div class="w-full pb-[10px]">
              <label>{{ $t('git.transaction.start_date') }}</label>
              <date-picker
                  valueType="format"
                  class="w-100"
                  v-model="paramsQuery.start_date"
                  prefix-class="xmx"
                  :placeholder="$t(`git.transaction.start_date`)"
              />
            </div>
            <div class="w-full pb-[10px]">
              <label>{{ $t('git.transaction.end_date') }}</label>
              <date-picker
                  valueType="format"
                  class="w-100"
                  v-model="paramsQuery.end_date"
                  prefix-class="xmx"
                  :placeholder="$t(`git.transaction.end_date`)"
              />
            </div>
            <div class="w-full flex justify-end gap-3">
              <span v-on:click="onOpenFilter"
                    class="w-[30%] text-sm rounded h-[30px] py-[5px] text-center cursor-pointer bg-white border border-[5px] shadow">
{{ $t('git.close') }}
              </span>
              <span v-on:click="filterTransaction"
                    class="w-[30%] text-sm rounded h-[30px] py-[5px] text-center cursor-pointer bg-[#4fcfc0] border border-[5px] shadow">
{{ $t('git.filter') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
        class="scrol-area b-tablesticky-header table-responsive h-[500px] overflow-y-scroll overflow-x-hidden ">
      <table class="table table-hover ">
        <thead class="bg-white  sticky">
        <tr>
          <th
              v-for="(item, i) in fields"
              :key="i"
              class="text-center whitespace-nowrap p-2 md:p-0 bg-white" :class="item.min_width"
          >
            <div
                class="flex justify-between gap-5 items-center w-full"
            >
                        <span class="text-xs text-[#0E0AB8]">{{
                            $t(item.label)
                          }}</span>
            </div>
          </th>
          <th class="text-center whitespace-nowrap p-2 md:p-0 bg-white"></th>
        </tr>
        </thead>
        <tbody class="overflow-y-scroll">
        <tr v-for="(transaction,i) in transactions.results" :key="i">
          <td>{{ transaction.event_id }}</td>
          <td>{{ transaction.club_account_name }}</td>
          <td>{{ formatTime(transaction) }}</td>
          <td>{{ transaction.user
          ? (transaction.user.full_name && transaction.user.email)
            ? `${transaction.user.full_name}, ${transaction.user.email}`
            : transaction.user.full_name || transaction.user.email
          : '' }}</td>
          <td>
            <span v-if="transaction.eng_status === 'Successful'">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8.5 17C13.1946 17 17 13.1946 17 8.5C17 3.80545 13.1946 0 8.5 0C3.80545 0 0 3.80545 0 8.5C0 13.1946 3.80545 17 8.5 17ZM13.0654 5.80975C13.1051 5.76879 13.1362 5.72023 13.1568 5.66698C13.1774 5.61373 13.187 5.55688 13.1852 5.49982C13.1833 5.44276 13.17 5.38666 13.1459 5.33487C13.1219 5.28308 13.0877 5.23666 13.0453 5.19839C13.003 5.16011 12.9533 5.13077 12.8994 5.1121C12.8454 5.09343 12.7883 5.08582 12.7313 5.08972C12.6744 5.09363 12.6188 5.10897 12.5679 5.13484C12.517 5.1607 12.4718 5.19655 12.4351 5.24025L7.344 10.866L4.54325 8.1923C4.46176 8.11441 4.35266 8.07209 4.23996 8.07464C4.12726 8.07719 4.02019 8.12441 3.9423 8.2059C3.86441 8.28739 3.82209 8.39649 3.82464 8.50919C3.82719 8.62189 3.87441 8.72896 3.9559 8.80685L7.07285 11.7819L7.38863 12.0836L7.68145 11.7598L13.0654 5.80975Z"
                      fill="#419E6A"/>
                </svg>
            </span>
            <span v-else-if="transaction.eng_status === 'Ongoing'">
              <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13H9v6l5.25 3.15.75-1.23-4.5-2.67V7z" fill="currentColor"/>
              </svg>
            </span>
            <span v-else>
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M5.1 9.35H11.9C12.1408 9.35 12.3428 9.2684 12.506 9.1052C12.6692 8.942 12.7506 8.74027 12.75 8.5C12.75 8.25917 12.6684 8.05715 12.5052 7.89395C12.342 7.73075 12.1403 7.64943 11.9 7.65H5.1C4.85917 7.65 4.65715 7.7316 4.49395 7.8948C4.33075 8.058 4.24943 8.25973 4.25 8.5C4.25 8.74083 4.3316 8.94285 4.4948 9.10605C4.658 9.26925 4.85973 9.35057 5.1 9.35ZM8.5 17C7.32417 17 6.21917 16.7767 5.185 16.3302C4.15083 15.8837 3.25125 15.2782 2.48625 14.5137C1.72125 13.7487 1.11577 12.8492 0.6698 11.815C0.223833 10.7808 0.000566667 9.67583 0 8.5C0 7.32417 0.223267 6.21917 0.6698 5.185C1.11633 4.15083 1.72182 3.25125 2.48625 2.48625C3.25125 1.72125 4.15083 1.11577 5.185 0.6698C6.21917 0.223833 7.32417 0.000566667 8.5 0C9.67583 0 10.7808 0.223267 11.815 0.6698C12.8492 1.11633 13.7487 1.72182 14.5137 2.48625C15.2787 3.25125 15.8845 4.15083 16.331 5.185C16.7776 6.21917 17.0006 7.32417 17 8.5C17 9.67583 16.7767 10.7808 16.3302 11.815C15.8837 12.8492 15.2782 13.7487 14.5137 14.5137C13.7487 15.2787 12.8492 15.8845 11.815 16.331C10.7808 16.7776 9.67583 17.0006 8.5 17ZM8.5 15.3C10.3983 15.3 12.0062 14.6412 13.3237 13.3237C14.6412 12.0062 15.3 10.3983 15.3 8.5C15.3 6.60167 14.6412 4.99375 13.3237 3.67625C12.0062 2.35875 10.3983 1.7 8.5 1.7C6.60167 1.7 4.99375 2.35875 3.67625 3.67625C2.35875 4.99375 1.7 6.60167 1.7 8.5C1.7 10.3983 2.35875 12.0062 3.67625 13.3237C4.99375 14.6412 6.60167 15.3 8.5 15.3Z"
    fill="#F43C3C"/>
</svg>

               </span>
          </td>
          <td>
           <span class="cursor-pointer" v-on:click="onOpenTransactionDetail(transaction)">
              <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M19.8698 6.962C20.3438 7.582 20.3438 8.419 19.8698 9.038C18.3768 10.987 14.7948 15 10.6128 15C6.4308 15 2.8488 10.987 1.3558 9.038C1.12519 8.74113 1 8.37592 1 8C1 7.62408 1.12519 7.25887 1.3558 6.962C2.8488 5.013 6.4308 1 10.6128 1C14.7948 1 18.3768 5.013 19.8698 6.962V6.962Z"
                  stroke="#5855D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                  d="M10.6128 11C12.2696 11 13.6128 9.65685 13.6128 8C13.6128 6.34315 12.2696 5 10.6128 5C8.95594 5 7.61279 6.34315 7.61279 8C7.61279 9.65685 8.95594 11 10.6128 11Z"
                  stroke="#5855D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
           </span>

          </td>
        </tr>
        </tbody>
        <table-shimmer :n-col="fields.length +1" :n-row="8" v-if="loading === true"/>
      </table>
    </div>
    <div class="flex justify-end">
      <paginate
          v-model="pagination.currentPage"
          :page-count="pagination.total"
          :click-handler="firePagination"
          :prev-text="'<'"
          :next-text="'>'"
          :page-class="'page-item c-mr-1 border-unset'"
          prev-link-class="page-link c-mr-1 border-unset"
          next-link-class="page-link c-mr-1 border-unset"
          next-class="next-item"
          prev-class="prev-item"
          page-link-class="page-link c-mr-1 border-unset"
          active-class="active c-mr-1 border-unset"
          :container-class="'pagination  b-pagination'"
      >
      </paginate>
    </div>
    <Modal
        :id="`id_${name}`"
        :hideFooter="true"
        :hide-header="true"
        :size="'lg'"
        :close-event="closeEvent"
    >
      <div class="w-full bg-white py-[10px]">
        <div class="w-full py-[5px]">
          <h5 class="font-semibold text-[20px]">Sammanfattning</h5>
        </div>
        <div class="w-full py-[5px]" v-if="transaction.summary">
          <div v-if="name === 'transaction_log_members'">
            <div v-if="transaction.summary" class="mb-[3px] text-[15px]"><span
                class="font-semibold">{{ $t('git.transaction.total_member_created') }}</span>:
              <div v-if="transaction.summary.members_created">
                <div class="grid grid-cols-2 lg:grid-cols-7 gap-6">
                  <p class="mr-[10px]" v-for="(it,i) in transaction.summary.members_created">{{ it }},</p>
                </div>
              </div>
            </div>
            <div v-if="transaction.summary" class="mb-[3px] text-[15px]"><span
                class="font-semibold">{{ $t('git.transaction.total_member_updated') }}</span>:
              <div v-if="transaction.summary.members_updated">
                <div class="grid grid-cols-2 lg:grid-cols-7 gap-6">
                  <p class="mr-[10px]" v-for="(it,i) in transaction.summary.members_updated">{{ it }},</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="name === 'transaction_log_articles'">
            <div v-if="transaction.summary" class="mb-[3px] text-[15px]"><span
                class="font-semibold">{{ $t('git.transaction.total_article_created') }}</span>:
              <div v-if="transaction.summary.articles_created">
                <div class="grid grid-cols-2 lg:grid-cols-7 gap-6">
                  <p class="mr-[10px]" v-for="(it,i) in transaction.summary.articles_created">{{ it }},</p>
                </div>
              </div>
            </div>
            <div v-if="transaction.summary" class="mb-[3px] text-[15px]"><span
                class="font-semibold">{{ $t('git.transaction.total_article_updated') }}</span>:
              <div v-if="transaction.summary.articles_updated">
                <div class="grid grid-cols-2 lg:grid-cols-7 gap-6">
                  <p class="mr-[10px]" v-for="(it,i) in transaction.summary.articles_updated">{{ it }},</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="name === 'transaction_log_invoices'">
            <div v-if="transaction.summary" class="mb-[3px] text-[15px]"><span
                class="font-semibold">{{ $t('git.transaction.total_invoices_created') }}</span>:
              <div v-if="transaction.summary.invoices_created">
                <div class="grid grid-cols-2 lg:grid-cols-7 gap-6">
                  <p class="mr-[10px]" v-for="(it,i) in transaction.summary.invoices_created">{{ it }},</p>
                </div>
              </div>
            </div>
            <div v-if="transaction.summary" class="mb-[3px] text-[15px]"><span
                class="font-semibold">{{ $t('git.transaction.total_invoices_booked') }}</span>:
              <div v-if="transaction.summary.invoices_booked">
                <div class="grid grid-cols-2 lg:grid-cols-7 gap-6">
                  <p class="mr-[10px]" v-for="(it,i) in transaction.summary.invoices_booked">{{ it }},</p>
                </div>
              </div>
            </div>
            <div v-if="transaction.summary" class="mb-[3px] text-[15px]"><span
                class="font-semibold">{{ $t('git.transaction.total_invoices_sent') }}</span>:
              <div v-if="transaction.summary.invoices_sent">
                <div class="grid grid-cols-2 lg:grid-cols-7 gap-6">
                  <p class="mr-[10px]" v-for="(it,i) in transaction.summary.invoices_sent">{{ it }},</p>
                </div>
              </div>
            </div>
            <div v-if="transaction.summary" class="mb-[3px] text-[15px]"><span
                class="font-semibold">{{ $t('git.transaction.invoices_existing') }}</span>:
              <div v-if="transaction.summary.invoices_existing">
                <div class="grid grid-cols-2 lg:grid-cols-7 gap-6">
                  <p class="mr-[10px]" v-for="(it,i) in transaction.summary.invoices_existing">{{ it }},</p>
                </div>

              </div>
            </div>
          </div>
          <div v-if="transaction.eng_status" class="mt-[40px]">
            <div v-if="transaction.eng_status === 'Error' && transaction.errors.length > 0"
                 class="scrol-area b-tablesticky-header table-responsive h-[500px] overflow-y-scroll overflow-x-hidden ">
              <table class="table table-hover ">
                <thead class="bg-white  sticky">
                <tr>
                  <th
                      class="text-center whitespace-nowrap p-2 md:p-0 bg-white"
                  >
                    <div
                        class="flex justify-between gap-5 items-center w-full"
                    >
                        <span class="text-xs text-[#0E0AB8]">{{
                            $t('git.transaction.resource_number')
                          }}</span>
                    </div>
                  </th>
                  <th
                      class="text-center whitespace-nowrap p-2 md:p-0 bg-white"
                  >
                    <div
                        class="flex justify-between gap-5 items-center w-full"
                    >
                        <span class="text-xs text-[#0E0AB8]">{{
                            $t('git.transaction.action')
                          }}</span>
                    </div>
                  </th>

                  <th
                      class="text-center whitespace-nowrap p-2 md:p-0 bg-white"
                  >
                    <div
                        class="flex justify-between gap-5 items-center w-full"
                    >
                        <span class="text-xs text-[#0E0AB8]">{{
                            $t('git.transaction.message')
                          }}</span>
                    </div>
                  </th>

                </tr>
                </thead>
                <tbody class="overflow-y-scroll">
                <tr v-for="(item,i) in transaction.errors" :key="i">
                  <td><span>{{ item.resource }}</span></td>
                  <td><span>{{ item[locale_action] }}</span></td>
                  <td><span>{{ item[locale_message] }}</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide(`id_${name}`)"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import moment from "moment";
import {mapGetters} from "vuex"
import DatePicker from "vue2-datepicker";
import TableShimmer from "@core/components/loading/TableShimmer";

export default {
  name: "TransactionLog",
  props: {
    endpoint: {
      type: String,
      default: 'transaction_log_members'
    },
    name: {
      type: String,
      default: 'member'
    },
  },
  components: {
    paginate: Paginate,
    DatePicker,
    TableShimmer
  },
  data: function () {
    return {
      transaction: {},
      openFilter: false,
      isModalVisible: false,
      start_date: '',
      pagination: {
        total: 1,
        currentPage: 1
      },
      fields: [
        {
          label: 'git.transaction.event_id',
          min_width: 'min-w-[233px]'
        },
        {
          label: 'git.transaction.club_account',
          min_width: 'min-w-[200px]'
        },
        {
          label: 'git.transaction.export_time',
          min_width: 'min-w-[200px]'
        },
        {
          label: 'git.transaction.user',
          min_width: 'min-w-[200px]'
        },
        {
          label: 'git.transaction.status',
          min_width: 'min-w-[50px]'
        },
      ],
      transactions: {
        results: []
      },
      search: '',
      limit: 20,
      paramsQuery: {
        start_date: '',
        end_date: '',
        eng_status: ''
      },
      loading: true
    }
  },
  methods: {
    closeEvent: function () {
      this.$bvModal.hide(`id_${this.name}`)
    },
    firePagination: function (num) {
      let payload = `?limit=${this.limit}&page=${num}`

      this.getTransactionLog(payload)
    },
    filterTransaction() {
      this.openFilter = false
      let payload = `?limit=${this.limit}`
      Object.keys(this.paramsQuery).map(key => {
        if (this.paramsQuery[key]) {
          payload += `&${key}=${this.paramsQuery[key]}`
        }
      })
      this.getTransactionLog(payload)

    },
    onOpenTransactionDetail: function (transaction) {
      this.transaction = transaction
      this.isModalVisible = true
      this.$bvModal.show(`id_${this.name}`)

    },
    getTransactionLog: function (query = '') {
      this.$useJwt.getGitAction({'method': 'get', URL: `${this.endpoint}/${query}`}).then(res => {
        this.transactions.results = res.data.results
        this.pagination.total = Math.ceil(res.data.count / this.limit)
        this.loading = false
      }).catch(e => {
        this.loading = false
      })
    },
    formatTime: function (transaction) {
      let dt = ''
      if (transaction.start_time) {
        dt = transaction.start_time
      }

      if (dt === '') {
        return ''
      }
      return moment(dt).format('HH:mm, YYYY-MM-DD')
    },
    onOpenFilter: function () {
      this.openFilter = !this.openFilter
    }
  },
  mounted() {
    this.getTransactionLog(`?limit=${this.limit}`)
  },
  computed: {
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'swe_error_message' : 'eng_error_message'
    },
    locale_action() {
      return this.GET_LOCALE === 'sv' ? 'swe_action' : 'eng_action'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  }
}
</script>

<style scoped>

</style>