<template>
  <div class="py-[10px]">
    <div class="w-full bg-white p-[10px] h-full">
      <div class="mt-[15px]">
        <invoice-list @update:showArchived="handleShowArchivedChange"/>
      </div>
      <div class="mt-[20px]">
        <div class="block lg:flex gap-3">
          <div class="w-full lg:w-[30%] px-[10px]">
            <div class="w-full pb-[20px]">
              <h5 class="text-[20px] font-semibold">{{ $t('git.actions') }}</h5>
            </div>
            <div class="grid grid-cols-1 gap-3">
              <div class="py-[5px]">
                <button v-if="!showArchived"
                        v-b-tooltip="$t('git.invoice.occasions.fetch_all_invoice_from_git')"
                        v-on:click="onActions('fetch_all_invoice_from_git')"
                        v-ripple.400
                        size="lg"
                        class="text-white w-full rounded bg-[#4fcfc0] py-[10px] hover:drop-shadow"

                >
          <span

              v-text="$t('git.invoice.occasions.fetch_all_invoice_from_git')"
          />
                </button>
              </div>
              <div class="py-[5px]">
                <button v-if="!showArchived"
                        v-b-tooltip="$t('git.invoice.occasions.import_all_invoice_to_acc')"
                        v-on:click="onActions('import_all_invoice_to_acc')"
                        v-ripple.400
                        size="lg"
                        class="text-white w-full rounded bg-[#4fcfc0] py-[10px] hover:drop-shadow"

                >
          <span

              v-text="$t('git.invoice.occasions.import_all_invoice_to_acc')"
          />
                </button>
              </div>
              <div class="py-[5px]">
                <button v-if="!showArchived"
                        v-b-tooltip="$t('git.invoice.occasions.import_selected_invoice_to_acc')"
                        v-on:click="onActions('import_selected_invoice_to_acc')"
                        v-ripple.400
                        size="lg"
                        class="text-white w-full rounded bg-[#4fcfc0] py-[10px] hover:drop-shadow"

                >
          <span

              v-text="$t('git.invoice.occasions.import_selected_invoice_to_acc')"
          />
                </button>
              </div>
            </div>
            <div class="py-[5px]">
            <!-- Archive Button -->
            <button v-if="!showArchived"
                    v-on:click="onActions('archive_occasions')"
                    class="text-white w-full rounded bg-blue-500 py-[10px] hover:drop-shadow">
              <span v-text="$t('git.invoice.occasions.archive_selected_occasions')"/>
            </button>
          </div>
          <div class="py-[5px]">
            <!-- Unarchive Button -->
            <button v-if="showArchived"
                    v-on:click="onActions('unarchive_occasions')"
                    class="text-white w-full rounded bg-green-500 py-[10px] hover:drop-shadow">
                    <span v-text="$t('git.invoice.occasions.unarchive_selected_occasions')"/>
            </button>
          </div>

          </div>
          <div class="w-full lg:w-[70%] px-[10px] mt-[40px] lg:mt-[0px]">
            <TransactionLog :name="'transaction_log_invoices'" :endpoint="'transaction_log_invoices'"/>
          </div>
        </div>
      </div>
    </div>
    <Modal
        id="git-invoice-occassion-actions"
        :hideFooter="true"
        :title="$t('confirmation')"
        :close-event="onCloseModal"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          {{ $t(`${actionText[actionType]}`) }}
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            :disabled="loading"
            @click="$bvModal.hide('git-invoice-occassion-actions')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            v-on:click="submitAction"
            :disabled="loading"
            variant="primary"
            size="md"
        >
              <span
                  v-text="loading ? $t('Message.loading') : $t('confirm')"
              />
        </b-button>
      </div>
    </Modal>
    <Modal
        id="git-actions-invoice-occasions"
        :hideFooter="true"
        :hide-header="true"
        :close-event="onCloseModal"
        :size="'lg'"
        :title="$t('confirmation')"
        no-close-on-backdrop
    >

      <invoice-occassion-form @submitData="onSubmitInvoiceForm" @close="onCloseModal" :loading="loading"/>
    </Modal>
  </div>
</template>

<script>
import InvoiceList from "@core/components/golfrelated/git/invoices/occasions/InvoiceList";
import TransactionLog from "@core/components/golfrelated/git/members/TransactionLog";
import {mapGetters} from "vuex"
import InvoiceOccassionForm from "@core/components/golfrelated/git/invoices/occasions/InvoiceOccassionForm";

export default {
  name: "InvoiceOccassion",
  components: {InvoiceList, TransactionLog, InvoiceOccassionForm},
  data: function () {
    return {
      showArchived: false,
      loading: false,
      actionType: 'fetch_all_invoice_from_git',
      actionText: {
        import_selected_invoice_to_acc: 'git.invoice.occasions.import_selected_invoice_to_acc_confirmation',
        fetch_all_invoice_from_git: 'git.invoice.occasions.fetch_all_invoice_from_git_confirmation',
        import_all_invoice_to_acc: 'git.invoice.occasions.import_all_invoice_to_acc_confirmation',
      }
    }
  },
  methods: {
    handleShowArchivedChange(value) {
      this.showArchived = value;
    },
    onActions: function (actionType) {
      if (actionType === 'import_selected_invoice_to_acc' || actionType === 'archive_occasions' || actionType === 'unarchive_occasions') {
        if (this.GET_SELECTED_INVOICE_OCCASION.length === 0) {
          this.popupMsg(
              this.$t("Message.Failed"),
              this.$t('git.invoice.occasions.no_invoice_selected'),
              "AlertTriangleIcon",
              "danger"
          );
          return false
        }
      }
      this.actionType = actionType
      if (actionType === 'fetch_all_invoice_from_git') {
        this.$bvModal.show("git-invoice-occassion-actions");
      } else if (actionType === 'import_all_invoice_to_acc' || actionType === 'import_selected_invoice_to_acc') {
        this.$bvModal.show("git-actions-invoice-occasions");
      } else {
        this.submitAction()
      }

    },
    onCloseModal: function () {
      this.$bvModal.hide('git-actions-invoice-occasions')
      this.$bvModal.hide('git-invoice-occassion-actions')
    },
    submitAction: function () {
      let payload = {}
      let mode = {method: 'post', URL: 'fetch_invoice_data/'}
      if (this.actionType === 'import_all_invoice_to_acc') {
        mode['URL'] = 'import_invoices/'
      } else if (this.actionType === 'import_selected_invoice_to_acc') {
        mode['URL'] = 'import_invoices/'
        payload['invoice_occasion_uuids'] = []
        this.GET_SELECTED_INVOICE_OCCASION.map(item => {
          payload['invoice_occasion_uuids'].push(item.uuid)
        })
      } else if (this.actionType === 'archive_occasions' || this.actionType === 'unarchive_occasions') {
        payload['invoice_occasion_uuids'] = []
        this.GET_SELECTED_INVOICE_OCCASION.map(item => {
          payload['invoice_occasion_uuids'].push(item.uuid)
        })
        if (this.actionType === 'archive_occasions') {
          mode['URL'] = 'archive_occasions/'
        } else if (this.actionType === 'unarchive_occasions') {
          mode['URL'] = 'unarchive_occasions/'
        }
      } else {
        mode['method'] = 'get'
      }
      this.loading = true
      this.$useJwt.getGitAction(mode, payload).then(resp => {
        const {data} = resp;

        this.popupMsg(
            this.$t('Message.Success'),
            data[this.locale_message],
            'CheckIcon',
            'success'
        );
        this.loading = false

        this.$bvModal.hide("git-invoice-occassion-actions");
        window.Bus.$emit('reload-invoice-occasion')
      }).catch(e => {
        this.loading = false
        this.$bvModal.hide("git-invoice-occassion-actions");
        this.popupMsg(
            this.$t("Message.Failed"),
            e.response.data[this.locale_message],
            "AlertTriangleIcon",
            "danger"
        );
      })
    },
    onSubmitInvoiceForm: function (data) {
      let mode = {method: 'post', URL: 'import_invoices/',}
      let payload = {'settings': data}
      if (this.actionType === 'import_selected_invoice_to_acc') {
        payload['invoice_occasion_uuids'] = []
        this.GET_SELECTED_INVOICE_OCCASION.map(item => {
          payload['invoice_occasion_uuids'].push(item.uuid)
        })
      }
      this.loading = true
      this.$useJwt.getGitAction(mode, payload).then(resp => {
        const {data} = resp;
        this.popupMsg(
            this.$t('Message.Success'),
            data[this.locale_message],
            'CheckIcon',
            'success'
        );
        this.loading = false

        this.$bvModal.hide("git-actions-invoice-occasions");
        window.Bus.$emit('reload-invoice-occasion')
      }).catch(e => {
        this.loading = false
        this.$bvModal.hide("git-actions-invoice-occasions");
        this.popupMsg(
            this.$t("Message.Failed"),
            e.response.data[this.locale_message],
            "AlertTriangleIcon",
            "danger"
        );
      })
    }
  },
  computed: {
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_SELECTED_INVOICE_OCCASION: 'git/GET_SELECT_INVOICE_OCCASION',
      GET_LOCALE: 'appConfig/getLocale'
    })
  }
}
</script>

<style scoped>

</style>