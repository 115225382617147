<template>
  <div class="w-full bg-white p-[10px] h-full">
    <div v-if="section === 'invoice_occasion'">
      <invoice-occassion :key="section"/>
    </div>
    <div v-if="section === 'payments'">
      <paid-git-invoice :key="section"/>
    </div>
    <div v-if="section === 'credits'">
      <credited-git-invoice :key="section"/>
    </div>
  </div>
</template>

<script>
import InvoiceOccassion from "@core/components/golfrelated/git/InvoiceOccassion";
import PaidGitInvoice from "@core/components/golfrelated/git/PaidGitInvoice";
import CreditedGitInvoice from "@core/components/golfrelated/git/CreditedGitInvoice";

export default {
  name: "InvoiceComponent",
  components: {InvoiceOccassion, PaidGitInvoice, CreditedGitInvoice},
  props: {
    section: {
      type: String,
      default: 'invoice_occasion'
    }
  }
}
</script>

<style scoped>

</style>