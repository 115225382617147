<template>
  <div class="py-[10px]">
    <div class="w-full bg-white p-[10px] h-full">
      <div class="mt-[15px]">
        <article-list-component/>
      </div>
      <div class="mt-[20px]">
        <div class="block lg:flex gap-3">
          <div class="w-full lg:w-[30%] px-[10px]">
            <div class="w-full pb-[20px]">
              <h5 class="text-[20px] font-semibold">{{ $t('git.actions') }}</h5>
            </div>
            <div class="grid grid-cols-1 gap-3">
              <div class="py-[5px]">
                <button v-b-tooltip="$t('git.article.fetch_all_article_from_git')"
                        v-on:click="onActions('fetch_all_article_from_git')"
                        v-ripple.400
                        size="lg"
                        class="text-white w-full  rounded bg-[#4fcfc0] py-[10px] hover:drop-shadow"

                >
          <span

              v-text="$t('git.article.fetch_all_article_from_git')"
          />
                </button>
              </div>
              <div class="py-[5px]">
                <button v-b-tooltip="$t('git.article.import_all_article_to_acc')"
                        v-on:click="onActions('import_all_article_to_acc')"
                        v-ripple.400
                        size="lg"
                        class="text-white w-full  rounded bg-[#4fcfc0] py-[10px] hover:drop-shadow"

                >
          <span

              v-text="$t('git.article.import_all_article_to_acc')"
          />
                </button>
              </div>
              <div class="py-[5px]">
                <button v-b-tooltip="$t('git.article.import_selected_article_to_acc')"
                        v-on:click="onActions('import_selected_article_to_acc')"
                        v-ripple.400
                        size="lg"
                        class="text-white w-full  rounded bg-[#4fcfc0] py-[10px] hover:drop-shadow"

                >
          <span

              v-text="$t('git.article.import_selected_article_to_acc')"
          />
                </button>
              </div>
            </div>
          </div>
          <div class="w-full mt-[30px] lg:mt-[0px] lg:w-[70%] px-[10px]">
            <TransactionLog :endpoint="`transaction_log_articles`" :name="'transaction_log_articles'"/>
          </div>
        </div>
      </div>
    </div>
    <Modal
        id="git-article-actions"
        :hideFooter="true"
        :title="$t('confirmation')"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          {{ $t(`${actionText[actionType]}`) }}
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            :disabled="loading"
            @click="$bvModal.hide('git-article-actions')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            v-on:click="submitAction"
            :disabled="loading"
            variant="primary"
            size="md"
        >
              <span
                  v-text="loading ? $t('Message.loading') : $t('confirm')"
              />
        </b-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import articleListComponent from "@core/components/golfrelated/git/articles/articleListComponent";
import TransactionLog from "@core/components/golfrelated/git/members/TransactionLog";
import {mapGetters} from "vuex"

export default {
  name: "ArticleComponent",
  components: {
    articleListComponent, TransactionLog
  },
  data: function () {
    return {
      loading: false,
      actionType: 'fetch_all_article_from_git',
      actionText: {
        import_selected_article_to_acc: 'git.article.import_selected_article_to_acc_confirmation',
        fetch_all_article_from_git: 'git.article.fetch_all_article_from_git_confirmation',
        import_all_article_to_acc: 'git.article.import_all_article_to_acc_confirmation',
      }
    }
  },
  methods: {
    onActions: function (actionType) {
      if (actionType === 'import_selected_article_to_acc') {
        if (this.GET_SELECTED_ARTICLE.length === 0) {
          this.popupMsg(
              this.$t("Message.Failed"),
              this.$t('git.article.no_article_selected'),
              "AlertTriangleIcon",
              "danger"
          );
          return false
        }
      }
      this.actionType = actionType
      this.$bvModal.show("git-article-actions");
    },
    submitAction: function () {
      let payload = {}
      let mode = {method: 'post', URL: 'fetch_article_data/'}
      if (this.actionType === 'import_all_article_to_acc') {
        mode['URL'] = 'import_articles/'
      } else if (this.actionType === 'import_selected_article_to_acc') {
        mode['URL'] = 'import_articles/'
        payload['golf_article_uuids'] = []
        this.GET_SELECTED_ARTICLE.map(item => {
          payload['golf_article_uuids'].push(item.uuid)
        })
      } else {
        mode['method'] = 'get'
      }
      this.loading = true
      this.$useJwt.getGitAction(mode, payload).then(resp => {
        const {data} = resp;

        this.popupMsg(
            this.$t('Message.Success'),
            data[this.locale_message],
            'CheckIcon',
            'success'
        );
        this.loading = false

        this.$bvModal.hide("git-article-actions");
        window.Bus.$emit('reload-git-article')
      }).catch(e => {
        this.loading = false
        this.$bvModal.hide("git-article-actions");
        this.popupMsg(
            this.$t("Message.Failed"),
            e.response.data[this.locale_message],
            "AlertTriangleIcon",
            "danger"
        );
      })
    }
  },
  computed: {
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_SELECTED_ARTICLE: 'git/GET_SELECT_ARTICLES',
      GET_LOCALE: 'appConfig/getLocale'
    })
  }
}
</script>

<style scoped>

</style>