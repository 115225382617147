<template>
  <div class="mt-[20px] lg:mt-[0px]">
    <div class="block lg:flex">
      <div class="w-full lg:w-[80%]">
        <div
            class="bg-secondaryColor px-[10px] py-[10px] rounded w-full tablet:w-[50%] laptop:w-[60%] desktop:w-[55%] d-flex bigGap align-items-center tabWrappermall"

        >
          <!-- width: 120px -->
          <div class="grid grid-cols-3 gap-3 w-full">
               <span
                   v-for="(data, index) in tabs"
                   :key="index"
               >
                                                <b-button
                                                    v-if="data.active"
                                                    size="sm"
                                                    @click="switchTabs(index)
                                                    "
                                                    class="w-[100%] px-[10px]"
                                                    variant="light"
                                                    v-text="$t(`${data.label}`)"
                                                />

                                                <p
                                                    v-else
                                                    @click="switchTabs(index)
                                                    "
                                                    class="mb-0 text-light py-[5px]  w-[100%] text-center"
                                                    style="font-size: 15px"
                                                    role="button"
                                                    v-text="$t(`${data.label}`)"
                                                />
                                            </span>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-[40%]" v-if="isInvoiceActive === true">
        <div class="flex justify-start lg:justify-end pt-[20px] lg:py-[10px]">
          <div class=" cursor-pointer" v-on:click="switchMinTab(key)" v-for="(item,key) in miniTab" :key="key">
            <span class="px-[15px] text-[15px] text-[#16149F] font-semibold cursor-pointer">
              {{ $t(`${item.label}`) }}
          </span>
            <div class="w-full bg-[#16149F] h-[2px]" v-if="item.active">

            </div>
          </div>
        </div>
      </div>
      <div class="sm:text-left lg:text-right">
        <button type="button" class="btn btn-primary ml-4" v-on:click="onOpenModal">Guide</button>
      </div>
    </div>
    <div class="flex flex-col mt-2">
      <KeepAlive>
        <!-- <transition name="zoom-fade" mode="out-in"> -->
        <component :is="currentComponent" :section="section"/>
        <!-- </transition> -->
      </KeepAlive>
      <CustomNoHeaderModalVue v-if="openModal" width="w-full md:w-3/4" :title="''" @handleClose="openModal = false"
                            :close="!true">

      <div class="flex flex-col" slot="body" id="export-content">
        <div id="pdfvuer">
          <!--<pdf-frame/>-->
          <iframe src="../../exports/application_guide.pdf#toolbar=0" id="exportPdf" style="height: 900px;width: 100%"/>
        </div>
      </div>
    </CustomNoHeaderModalVue>
    </div>

  </div>
</template>

<script>
import InvoiceComponent from "@core/components/golfrelated/git/InvoiceComponent";
import MembersComponent from "@core/components/golfrelated/git/MembersComponent";
import ArticleComponent from "@core/components/golfrelated/git/ArticleComponent";
import PaidGitInvoice from "@core/components/golfrelated/git/PaidGitInvoice";
import CreditedGitInvoice from "@core/components/golfrelated/git/CreditedGitInvoice";
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';

export default {
  name: "gitComponent",
  components: {InvoiceComponent, ArticleComponent, MembersComponent, PaidGitInvoice, CreditedGitInvoice, CustomNoHeaderModalVue},
  data: function () {
    return {
      openModal: false,
      currentComponent: InvoiceComponent,
      section: 'invoice_occasion',
      tabs: [
        {
          label: 'git.invoices',
          components: 'InvoiceComponent',
          active: true,
          name: 'invoice'
        },
        {
          label: 'git.members',
          components: 'MembersComponent',
          active: false,
          name: 'member'
        },
        {
          label: 'git.articles',
          components: 'ArticleComponent',
          active: false,
          name: 'article'
        },
      ],
      miniTab: [
        {
          label: 'git.payments',
          name: 'payments',
          components: 'PaidGitInvoice',
          active: false,
        },
        {
          label: 'git.credits',
          components: 'CreditedGitInvoice',
          name: 'credits',
          active: false,
        },
      ]
    }
  },
  methods: {
    onOpenModal: function () {
      this.openModal = !this.openModal
    },
    switchTabs(index) {
      this.tabs.find(item => item.active === true).active = false;
      this.section = this.tabs[index].name === 'invoice' ? 'invoice_occasion' : ''
      this.currentComponent = this.tabs[index].components;
      this.tabs[index].active = true;
      this.miniTab.map(k => {
        k.active = false
      })
    },
    switchMinTab: function (index) {
      this.miniTab.map(item => {
        item.active = false
      })
      this.section = this.miniTab[index].name
      this.miniTab[index].active = true
    }
  },
  computed: {
    isInvoiceActive: function () {
      return this.tabs.filter(item => item.active === true)[0].name === 'invoice'
    }
  }
}
</script>

<style scoped>

</style>