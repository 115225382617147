<template>
  <div>
    <div class="flex justify-between px-[10px]">
      <div class="w-[50%]">
        <h5 class="px-[5px] py-[10px]">{{ $t('git.invoice.occasions.title') }}</h5>
        <!-- Archived/Unarchived Toggle -->
        <div class="flex items-center mt-2"> <!-- Flex container for alignment -->
          <label class="switch">
            <input type="checkbox" v-model="showArchived">
            <span class="slider round"></span>
          </label>
          <span class="ml-2 text-sm">{{ $t('git.invoice.occasions.showArchived') }}</span> <!-- Text next to toggle -->
        </div>
      </div>
      <div class="w-[50%] flex justify-end hidden">
        <div class="w-full">
          <div class="flex justify-end">
            <div class="relative w-[60%]">
                <span class="absolute left-[10px] top-[10px]">
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M16.2781 15.0205L11.7131 10.4555C12.4215 9.53965 12.8047 8.41992 12.8047 7.24219C12.8047 5.83242 12.2545 4.51055 11.2596 3.51387C10.2646 2.51719 8.93926 1.96875 7.53125 1.96875C6.12324 1.96875 4.79785 2.51895 3.80293 3.51387C2.80625 4.50879 2.25781 5.83242 2.25781 7.24219C2.25781 8.6502 2.80801 9.97559 3.80293 10.9705C4.79785 11.9672 6.12148 12.5156 7.53125 12.5156C8.70898 12.5156 9.82695 12.1324 10.7428 11.4258L15.3078 15.9891C15.3212 16.0025 15.3371 16.0131 15.3546 16.0203C15.3721 16.0276 15.3908 16.0313 15.4098 16.0313C15.4287 16.0313 15.4475 16.0276 15.4649 16.0203C15.4824 16.0131 15.4983 16.0025 15.5117 15.9891L16.2781 15.2244C16.2915 15.211 16.3021 15.1951 16.3094 15.1776C16.3166 15.1601 16.3204 15.1414 16.3204 15.1225C16.3204 15.1035 16.3166 15.0848 16.3094 15.0673C16.3021 15.0498 16.2915 15.0339 16.2781 15.0205ZM10.3156 10.0266C9.57031 10.7701 8.58242 11.1797 7.53125 11.1797C6.48008 11.1797 5.49219 10.7701 4.74688 10.0266C4.00332 9.28125 3.59375 8.29336 3.59375 7.24219C3.59375 6.19102 4.00332 5.20137 4.74688 4.45781C5.49219 3.71426 6.48008 3.30469 7.53125 3.30469C8.58242 3.30469 9.57207 3.7125 10.3156 4.45781C11.0592 5.20313 11.4688 6.19102 11.4688 7.24219C11.4688 8.29336 11.0592 9.28301 10.3156 10.0266Z"
    fill="#8F8E94"/>
</svg>

              </span>
              <input type="text" v-model="search"
                     class="h-[40px] pl-[36px] w-full rounded px-[10px] outline-1 outline-[#cfcfcf] py-[10px] border-[#cfcfcf] border"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
        class="scrol-area b-tablesticky-header table-responsive h-[500px] overflow-y-scroll overflow-x-hidden border rounded">
      <table class="table table-hover ">
        <thead class="bg-white  sticky">
        <tr>
          <th class="text-center whitespace-nowrap p-2 md:p-0 bg-white">
            <div
                class="flex justify-between gap-5 items-center w-full"
            >
                        <span class="text-xs text-gray-400">
                            <b-form-checkbox
                                v-model="checkAll"
                                v-on:change="onBulkSelectInvoice"
                                class="custom-control-primary"
                            />
                        </span>
            </div>
          </th>
          <th
              v-for="(item, i) in fields"
              :key="i"
              class="text-center whitespace-nowrap p-2 md:p-0 bg-white"
          >
            <div
                class="flex  gap-1 items-center w-full"
            >
                        <span class="text-xs text-[#0E0AB8]">{{
                            $t(item.label)
                          }}</span>
              <span class="flex">
                    <svg width="8" height="11" v-on:click="onSort(`-${item.value}`)" class="cursor-pointer"
                         viewBox="0 0 8 11" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M6.50003 6.29688C6.60437 6.29695 6.70637 6.32774 6.79331 6.38542C6.88026 6.44309 6.9483 6.52509 6.98894 6.62118C7.02958 6.71727 7.04103 6.8232 7.02186 6.92576C7.00268 7.02832 6.95373 7.12296 6.88111 7.19788L4.04778 10.1197C3.99827 10.1707 3.93903 10.2113 3.87357 10.2389C3.80811 10.2666 3.73777 10.2809 3.6667 10.2809C3.59563 10.2809 3.52529 10.2666 3.45983 10.2389C3.39437 10.2113 3.33513 10.1707 3.28561 10.1197L0.452281 7.19788C0.379664 7.12296 0.330715 7.02832 0.31154 6.92576C0.292364 6.8232 0.303813 6.71727 0.344456 6.62118C0.385098 6.52509 0.453137 6.44309 0.540082 6.38542C0.627027 6.32774 0.72903 6.29695 0.833364 6.29688H3.13545V1.25C3.13545 1.1091 3.19142 0.973978 3.29105 0.874349C3.39068 0.774721 3.5258 0.71875 3.6667 0.71875C3.80759 0.71875 3.94272 0.774721 4.04235 0.874349C4.14198 0.973978 4.19795 1.1091 4.19795 1.25V6.29688H6.50003Z"
                          fill="#606060"/>
                      </svg>
                    <svg width="8" height="11" v-on:click="onSort(`${item.value}`)" class="cursor-pointer"
                         viewBox="0 0 8 11" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.16678 4.70274C7.27111 4.70266 7.37312 4.67187 7.46006 4.6142C7.54701 4.55652 7.61504 4.47453 7.65569 4.37843C7.69633 4.28234 7.70778 4.17641 7.6886 4.07385C7.66943 3.97129 7.62048 3.87665 7.54786 3.80174L4.71453 0.879862C4.66501 0.828883 4.60577 0.788357 4.54032 0.760683C4.47486 0.733009 4.40451 0.71875 4.33345 0.71875C4.26238 0.71875 4.19203 0.733009 4.12658 0.760683C4.06112 0.788357 4.00188 0.828883 3.95236 0.879862L1.11903 3.80174C1.04641 3.87665 0.997463 3.97129 0.978288 4.07385C0.959113 4.17641 0.97056 4.28234 1.0112 4.37843C1.05185 4.47453 1.11988 4.55652 1.20683 4.6142C1.29378 4.67187 1.39578 4.70266 1.50011 4.70274H3.8022V9.74961C3.8022 9.89051 3.85817 10.0256 3.9578 10.1253C4.05742 10.2249 4.19255 10.2809 4.33345 10.2809C4.47434 10.2809 4.60947 10.2249 4.7091 10.1253C4.80873 10.0256 4.8647 9.89051 4.8647 9.74961V4.70274H7.16678Z"
                        fill="#606060"/>
                    </svg>

                  </span>
            </div>
          </th>
        </tr>
        </thead>
        <tbody class="overflow-y-scroll">
        <tr v-for="(invoice,i) in invoiceList.results" :key="i">
          <td><span class="text-xs text-gray-400">
                            <b-form-checkbox
                                v-model="invoice.checked"
                                v-on:change="onSelectInvoice(invoice)"
                                class="custom-control-primary"
                            />
                        </span></td>
          <td v-on:click="openDetail(invoice)">
            {{ invoice.name || formatTime(invoice.creation_date) }}
          </td>
          <td v-on:click="openDetail(invoice)">{{ invoice.event_id }}</td>
          <td v-on:click="openDetail(invoice)">{{ invoice.number_of_invoices }}</td>
          <td v-on:click="openDetail(invoice)">{{ formatTime(invoice.sync_started) }}</td>
          <td class="text-center">
                <span v-if="invoice.sync_completed === true">
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8.5 17C13.1946 17 17 13.1946 17 8.5C17 3.80545 13.1946 0 8.5 0C3.80545 0 0 3.80545 0 8.5C0 13.1946 3.80545 17 8.5 17ZM13.0654 5.80975C13.1051 5.76879 13.1362 5.72023 13.1568 5.66698C13.1774 5.61373 13.187 5.55688 13.1852 5.49982C13.1833 5.44276 13.17 5.38666 13.1459 5.33487C13.1219 5.28308 13.0877 5.23666 13.0453 5.19839C13.003 5.16011 12.9533 5.13077 12.8994 5.1121C12.8454 5.09343 12.7883 5.08582 12.7313 5.08972C12.6744 5.09363 12.6188 5.10897 12.5679 5.13484C12.517 5.1607 12.4718 5.19655 12.4351 5.24025L7.344 10.866L4.54325 8.1923C4.46176 8.11441 4.35266 8.07209 4.23996 8.07464C4.12726 8.07719 4.02019 8.12441 3.9423 8.2059C3.86441 8.28739 3.82209 8.39649 3.82464 8.50919C3.82719 8.62189 3.87441 8.72896 3.9559 8.80685L7.07285 11.7819L7.38863 12.0836L7.68145 11.7598L13.0654 5.80975Z"
                          fill="#419E6A"/>
                    </svg>

                </span>
            <span v-else>
                 <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M5.1 9.35H11.9C12.1408 9.35 12.3428 9.2684 12.506 9.1052C12.6692 8.942 12.7506 8.74027 12.75 8.5C12.75 8.25917 12.6684 8.05715 12.5052 7.89395C12.342 7.73075 12.1403 7.64943 11.9 7.65H5.1C4.85917 7.65 4.65715 7.7316 4.49395 7.8948C4.33075 8.058 4.24943 8.25973 4.25 8.5C4.25 8.74083 4.3316 8.94285 4.4948 9.10605C4.658 9.26925 4.85973 9.35057 5.1 9.35ZM8.5 17C7.32417 17 6.21917 16.7767 5.185 16.3302C4.15083 15.8837 3.25125 15.2782 2.48625 14.5137C1.72125 13.7487 1.11577 12.8492 0.6698 11.815C0.223833 10.7808 0.000566667 9.67583 0 8.5C0 7.32417 0.223267 6.21917 0.6698 5.185C1.11633 4.15083 1.72182 3.25125 2.48625 2.48625C3.25125 1.72125 4.15083 1.11577 5.185 0.6698C6.21917 0.223833 7.32417 0.000566667 8.5 0C9.67583 0 10.7808 0.223267 11.815 0.6698C12.8492 1.11633 13.7487 1.72182 14.5137 2.48625C15.2787 3.25125 15.8845 4.15083 16.331 5.185C16.7776 6.21917 17.0006 7.32417 17 8.5C17 9.67583 16.7767 10.7808 16.3302 11.815C15.8837 12.8492 15.2782 13.7487 14.5137 14.5137C13.7487 15.2787 12.8492 15.8845 11.815 16.331C10.7808 16.7776 9.67583 17.0006 8.5 17ZM8.5 15.3C10.3983 15.3 12.0062 14.6412 13.3237 13.3237C14.6412 12.0062 15.3 10.3983 15.3 8.5C15.3 6.60167 14.6412 4.99375 13.3237 3.67625C12.0062 2.35875 10.3983 1.7 8.5 1.7C6.60167 1.7 4.99375 2.35875 3.67625 3.67625C2.35875 4.99375 1.7 6.60167 1.7 8.5C1.7 10.3983 2.35875 12.0062 3.67625 13.3237C4.99375 14.6412 6.60167 15.3 8.5 15.3Z"
    fill="#F43C3C"/>
</svg>

               </span>
          </td>
          <td>
            <span v-if="invoice.create_contracts === true">{{ $t('git.contracts') }}</span>
            <span v-else-if="invoice.create_split_invoices === true">{{ $t('git.split_invoices') }}</span>
            <span v-else>{{ $t('git.invoices') }}</span>
          </td>
        </tr>
        </tbody>
        <table-shimmer :n-col="fields.length +1" :n-row="8" v-if="loading === true"/>
      </table>
    </div>
    <div class="flex justify-end">
      <paginate
          v-model="pagination.currentPage"
          :page-count="pagination.total"
          :click-handler="firePagination"
          :prev-text="'<'"
          :next-text="'>'"
          :page-class="'page-item c-mr-1 border-unset'"
          prev-link-class="page-link c-mr-1 border-unset"
          next-link-class="page-link c-mr-1 border-unset"
          next-class="next-item"
          prev-class="prev-item"
          page-link-class="page-link c-mr-1 border-unset"
          active-class="active c-mr-1 border-unset"
          :container-class="'pagination  b-pagination'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import moment from "moment";
import TableShimmer from "@core/components/loading/TableShimmer";

export default {
  name: "InvoiceList",
  components: {
    paginate: Paginate, TableShimmer
  },
  data: function () {
    return {
      showArchived: false,
      search: '',
      sortValue: '',
      checkAll: false,
      loading: true,
      invoiceList: {
        results: []
      },
      pagination: {
        currentPage: 1,
        total: 1
      },
      limit: 30,
      fields: [
      {
          label: 'git.invoice.occasions.name_or_creation_date',
          value: 'name'
        },
        {
          label: 'git.invoice.occasions.id',
          value: 'event_id'
        },
        {
          label: 'git.invoice.occasions.number_of_invoices',
          value: 'number_of_invoices'
        },
        {
          label: 'git.invoice.occasions.last_sync_to_accounting_started',
          value: 'last_sync_to_accounting_started'
        },
        {
          label: 'git.invoice.occasions.sync_to_accounting_completed',
          value: 'sync_to_accounting_completed'
        },
        {
          label: 'git.invoice.occasions.synced_as',
          value: 'synced_as'
        }
      ],
    }
  },
  methods: {
    formatTime: function (dt) {
      if (!dt) {
        return ''
      }
      let dte = moment(dt).format('HH:mm, YYYY-MM-DD')
      if (dte === 'Invalid date'){
        return ''
      }
      return moment(dt).format('HH:mm, YYYY-MM-DD')
    },
    onSelectInvoice: function (invoice) {
      if (invoice.checked === true) {
        this.$store.commit('git/UPDATE_SELECTED_INVOICE_OCCASION', {'action': 'single', data: invoice})
      } else {
        this.$store.commit('git/REMOVE_SELECTED_INVOICE_OCCASION', {action: 'single', uuid: invoice.uuid})
      }
    },
    onBulkSelectInvoice: function () {
      if (this.checkAll === true) {
        this.invoiceList.results.map(item => {
          item.checked = true
        })
        this.$store.commit('git/UPDATE_SELECTED_INVOICE_OCCASION', {'action': 'bulk', data: this.invoiceList.results})
      } else {
        this.invoiceList.results.map(item => {
          item.checked = false
        })
        this.$store.commit('git/REMOVE_SELECTED_INVOICE_OCCASION', {'action': 'bulk'})
      }
    },
    onSort: function (sortValue) {
      this.sortValue = sortValue
      let URL = this.computeParams()
      URL += `&ordering=${sortValue}`
      this.getInvoice(URL)
    },
    computeParams: function () {
      return `?limit=${this.limit}`
    },
    firePagination: function (num) {
      let URL = this.computeParams()
      URL += `&page=${num}`
      this.getInvoice(URL)

    },
    getInvoice: function (query = '') {
      const archivedParam = this.showArchived ? '&archived=True' : '&archived=False';
      this.$useJwt.getGitAction({URL: `list_invoices${query}${archivedParam}`, method: "get"}).then(resp => {
        resp.data.results.map(item => {
          item.checked = false
        })
        this.invoiceList = resp.data
        this.pagination.total = Math.ceil(resp.data.count / this.limit)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    openDetail(invoice) {
      this.$store.commit('git/UPDATE_SINGLE_INVOICE_OCCASION', invoice)
      let self = this
      setTimeout(function () {
        self.$router.push({name: 'golfRelatedGitSingleInvoiceOccasion', params: {'invoice_id': invoice.uuid}})
      }, 300)
    }
  },
  mounted() {
    this.getInvoice(`?limit=${this.limit}`)
    window.Bus.$on('reload-invoice-occasion', e => {
      this.checkAll = false
      this.$store.commit('git/REMOVE_SELECTED_INVOICE_OCCASION', {'action': 'bulk'})
      this.getInvoice(`?limit=${this.limit}`)
    })
  }, 
  watch: {
    showArchived(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getInvoice(this.computeParams());
        this.$emit('update:showArchived', this.showArchived);
      }
    }
  }
}
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Smaller width */
  height: 20px; /* Smaller height */
}

/* Toggle Slider */
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 10px; /* Adjusted for smaller toggle */
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 16px; /* Smaller circle */
  width: 16px; /* Smaller circle */
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

/* Adjusted position for the slider circle when toggle is checked */
input:checked + .slider:before {
  -webkit-transform: translateX(18px); /* Adjusted for correct positioning */
  -ms-transform: translateX(18px); /* Adjusted for correct positioning */
  transform: translateX(18px); /* Adjusted for correct positioning */
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
