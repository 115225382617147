<template>
  <div class="py-[10px]">
    <div class="bg-white w-full h-full border rounded p-[25px]">
      <div class="mt-[15px]">
        <membersListComponent/>
      </div>
      <div class="mt-[20px]">
        <div class="block lg:flex gap-3">
          <div class="w-full lg:w-[30%] px-[10px]">
            <div class="w-full pb-[20px]">
              <h5 class="text-[20px] font-semibold">{{ $t('git.actions') }}</h5>
            </div>
            <div class="grid grid-cols-1 gap-3">
              <div class="py-[5px]">
                <button v-b-tooltip="$t('git.member.fetch_all_member_from_git')"
                        v-on:click="onActions('fetch_all_member_from_git')"
                        v-ripple.400
                        size="lg"
                        class="text-white w-full  rounded bg-[#4fcfc0] py-[10px] hover:drop-shadow"

                >
                  <span v-text="$t('git.member.fetch_all_member_from_git')" />
                </button>
              </div>
              <div class="py-[5px]">
                <button v-b-tooltip="$t('git.member.fetch_one_member_from_git')"
                        v-on:click="onActions('fetch_one_member_from_git')"
                        v-ripple.400
                        size="lg"
                        class="text-white w-full rounded bg-[#4fcfc0] py-[10px] hover:drop-shadow"
                >
                  <span v-text="$t('git.member.fetch_one_member_from_git')" />
                </button>
              </div>
              <div class="py-[5px]">
                <button v-b-tooltip="$t('git.member.import_all_member_to_acc')"
                        v-on:click="onActions('import_all_member_to_acc')"
                        v-ripple.400
                        size="lg"
                        class="text-white w-full rounded bg-[#4fcfc0] py-[10px] hover:drop-shadow"

                >
          <span

              v-text="$t('git.member.import_all_member_to_acc')"
          />
                </button>
              </div>
              <div class="py-[5px]">
                <button v-b-tooltip="$t('git.member.import_selected_member_to_acc')"
                        v-on:click="onActions('import_selected_member_to_acc')"
                        v-ripple.400
                        size="lg"
                        class="text-white w-full rounded bg-[#4fcfc0] py-[10px] hover:drop-shadow"

                >
          <span

              v-text="$t('git.member.import_selected_member_to_acc')"
          />
                </button>
              </div>
            </div>
          </div>
          <div class="w-full mt-[30px] lg:mt-[0px] lg:w-[70%] px-[10px]">
            <TransactionLog :name="'transaction_log_members'" :endpoint="'transaction_log_members'"/>
          </div>
        </div>
      </div>

    </div>
    <Modal
        id="git-actions"
        :hideFooter="true"
        :title="$t('confirmation')"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          {{ $t(`${actionText[actionType]}`) }}
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            :disabled="loading"
            @click="$bvModal.hide('git-actions')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            v-on:click="submitAction"
            :disabled="loading"
            variant="primary"
            size="md"
        >
              <span
                  v-text="loading ? $t('Message.loading') : $t('confirm')"
              />
        </b-button>
      </div>
    </Modal>
    <Modal
        id="git-fetch-one"
        :hideFooter="true"
        :title="$t('git.member.enter_golf_id')"
      >
        <b-card-text class="d-flex flex-column text-center align-items-center justify-content-center" style="gap: 1rem">
          <!-- Add an input field here for the Golf-ID -->
          <b-form-input @keyup="onGolfIdInput" :value="golfId" />

        </b-card-text>
        <div class="flex justify-end gap-3 mt-2">
          <b-button variant="outline-danger" size="md" @click="$bvModal.hide('git-fetch-one')">
            <span v-text="$t('Message.cancel')" />
          </b-button>
          <b-button v-on:click="fetchOneMemberFromGit" variant="primary" size="md">
            <span v-text="$t('confirm')" />
          </b-button>
        </div>
      </Modal>

  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import membersListComponent from "@core/components/golfrelated/git/members/membersListComponent";
import TransactionLog from "@core/components/golfrelated/git/members/TransactionLog";
import {mapGetters} from "vuex"

export default {
  name: "MembersComponent",
  components: {
    paginate: Paginate,
    TransactionLog, membersListComponent
  },
  data: function () {
    return {
      golfId: '',
      loading: false,
      actionType: 'fetch_all_member_from_git',
      actionText: {
        import_selected_member_to_acc: 'git.member.import_selected_member_to_acc_confirmation',
        fetch_all_member_from_git: 'git.member.fetch_all_member_from_git_confirmation',
        import_all_member_to_acc: 'git.member.import_all_member_to_acc_confirmation',
      }
    }
  },
  methods: {
    onActions: function (actionType) {
      if (actionType === 'import_selected_member_to_acc') {
        if (this.GET_SELECTED_MEMBERS.length === 0) {
          this.popupMsg(
              this.$t("Message.Failed"),
              this.$t('git.member.no_member_selected'),
              "AlertTriangleIcon",
              "danger"
          );
          return false
        }
      }
      this.actionType = actionType
      if (actionType !== 'fetch_one_member_from_git') {
        this.$bvModal.show("git-actions");
      } else {
        this.$bvModal.show("git-fetch-one");
      }
      
    },
    submitAction: function () {
      let payload = {}
      let mode = {method: 'post', URL: 'fetch_member_data/'}
      if (this.actionType === 'import_all_member_to_acc') {
        mode['URL'] = 'import_members/'
      } else if (this.actionType === 'import_selected_member_to_acc') {
        mode['URL'] = 'import_members/'
        payload['member_uuids'] = []
        this.GET_SELECTED_MEMBERS.map(item => {
          payload['member_uuids'].push(item.uuid)
        })
      } else {
        mode['method'] = 'get'
      }
      this.loading = true
      this.$useJwt.getGitAction(mode, payload).then(resp => {
        const {data} = resp;

        this.popupMsg(
            this.$t('Message.Success'),
            data[this.locale_message],
            'CheckIcon',
            'success'
        );
        this.loading = false

        this.$bvModal.hide("git-actions");
        window.Bus.$emit('reload-git-member')
      }).catch(e => {
        this.loading = false
         this.$bvModal.hide("git-actions");
        this.popupMsg(
            this.$t("Message.Failed"),
            e.response.data[this.locale_message],
            "AlertTriangleIcon",
            "danger"
        );
      })
    }, 
    onGolfIdInput(event) {
      let value = event.target.value;

      // Allow only digits and a single dash
      value = value.replace(/[^0-9-]/g, '');

      // Automatically add a dash after the 6th digit
      if (value.length === 6 && !value.includes('-') && event.key !== "Backspace") {
        value += '-';
      }

      // Restrict the total length to 10 characters
      if (value.length > 10) {
        value = value.slice(0, 10);
      }

      // Ensure single hyphen at position 7
      if (value.includes('-') && value.indexOf('-') !== 6) {
        value = value.replace('-', '');  // Remove hyphen
        if (value.length >= 6) {  // Re-insert hyphen at correct position
          value = value.slice(0, 6) + '-' + value.slice(6);
        }
      }

      this.golfId = value;
      this.$nextTick(() => {
        event.target.value = value;
      });
    },





    fetchOneMemberFromGit() {
      if (this.golfId.length === 10) {
        const mode = { method: 'get', URL: 'fetch_member_data/?golf_id=' + this.golfId };
        this.$useJwt.getGitAction(mode).then(resp => {
          const {data} = resp;
          this.popupMsg(
              this.$t('Message.Success'),
              data[this.locale_message],
              'CheckIcon',
              'success'
          );
          this.$bvModal.hide("git-fetch-one");
          this.golfId = '';
        }).catch(e => {
          this.$bvModal.hide("git-fetch-one");
          this.golfId = '';
          this.popupMsg(
              this.$t("Message.Failed"),
              e.response.data[this.locale_message],
              "AlertTriangleIcon",
              "danger"
          );
        });
      } 
    },
  },
  mounted() {
  },
  computed: {
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_SELECTED_MEMBERS: 'git/GET_SELECT_MEMBERS',
      GET_LOCALE: 'appConfig/getLocale'
    })
  }, 
  watch: {
    golfId(newVal, oldVal) {
      if (newVal.length === 6 && oldVal.length === 5) {
        this.golfId += '-';
      }
    },
  },

}
</script>

<style scoped>

</style>